import React, { Component } from 'react';
import axios from 'axios';
import { withSize } from 'react-sizeme';
import Layout from '../components/Layout';
import ContactForm from '../components/ContactForm';
import SEO from '../components/SEO';
import ContactFormStyled from '../components/page-styles/creditapplication-styles';
import PageTitleBanner from '../components/PageTitleBanner';

class One9ContactForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            contactFormdata: {},
            honey: '',
            honeyIsValid: true,
            utmParams: {
                campaign: '',
                medium: '',
                source: '',
            },
        };
    }

    componentDidMount() {
        this.setUTMParams();
    }

    setUTMParams() {
        if (localStorage.getItem('utmCampaign') !== null) {
            localStorage.setItem('utmCampaign', '');
            localStorage.setItem('utmMedium', '');
            localStorage.setItem('utmSource', '');
        }

        let queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const utmCampaign = urlParams.get('utm_campaign');
        const utmMedium = urlParams.get('utm_medium');
        const utmSource = urlParams.get('utm_source');

        localStorage.setItem('utmCampaign', utmCampaign);
        localStorage.setItem('utmMedium', utmMedium);
        localStorage.setItem('utmSource', utmSource);

        this.setState(prevState => ({
            utmParams: {
                ...prevState.utmParams,
                campaign: utmCampaign,
                medium: utmMedium,
                source: utmSource,
            },
        }));
    }

    checkHoney = () => {
        let honey = document.getElementById('honey').value;
        this.setState({ honey: honey });

        if (honey === '') {
            this.setState({ honeyIsValid: true });
        } else {
            this.setState({ honeyIsValid: false });
        }
    };

    SubmitContactForm = data => {
        const tracker = window?.ga.getAll()[0];
        const GACLIENTID__c = tracker?.get('clientId');
        const GATRACKID__c = tracker?.get('trackingId');
        const GAUSERID__c  = tracker?.get('userId');

        this.checkHoney();
        if (this.state.honeyIsValid) {
            data.state = data.state.label;
            data["GACLIENTID__c"] = GACLIENTID__c;
            data["GATRACKID__c"] = GATRACKID__c;
            data["GAUSERID__c"] = GAUSERID__c ? GAUSERID__c : '' ;
            let url = `${process.env.PFJ_BASE_API_URL}${process.env.ONE9_EXPERIENCE_LAYER_URL}contact/submission`;
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
            'event': 'form-submit',
            'formName': 'Contact Us Form'
            });
            axios
                .post(url, data, {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                        apiKey: process.env.CONTACT_SBUMISSION_API_KEY,
                    },
                })
                .then(() => {
                   window.location.href = '/confirmation';
                })
                .catch(function(error) {
                    // handle error
                    console.log(error);
                });
        }
    };

    render() {
        const { size } = this.props;

        return (
            <Layout hideNav={true} needsSolidHeader={true} size={size}>
                <ContactFormStyled id="apply" className="applyForm_holder">
                    <SEO title="Credit Application" />
                    <PageTitleBanner title="Apply" />
                    <ContactForm
                        onSubmit={this.SubmitContactForm}
                        class="contact-form"
                        utmParams={this.state.utmParams}
                    ></ContactForm>
                </ContactFormStyled>
            </Layout>
        );
    }
}

export default withSize()(One9ContactForm);
