import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import HookFormInput from './UI/HookFormInput';
import styled from 'styled-components';
import HookFormCheckbox from './UI/HookFormCheckbox';
import HookFormSelect from './UI/HookFormSelect';
import CreditSubmitButton from './UI/CreditSubmitButton';
import { StateOptions } from './Constants';
import HookFormCheckboxGroup from './UI/HookFormCheckboxGroup';

const ContactUstyled = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    h2 {
        font-size: 1.5rem;
        margin-top: 32px;
        margin-bottom: 30px;
        position: relative;
        width: 100%;
        text-align: left;
        @media screen and (max-width: 768px) {
            font-size: 1.4rem;
        }
    }

    .inputHolder {
        width: 800px;
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 768px) {
            width: calc(100% - 40px);
        }
        @media screen and (max-width: 1024px) {
            width: calc(100% - 5rem);
        }
        .btn-group {
            display: flex;
            justify-content: flex-end;
            @media screen and (max-width: 768px) {
                justify-content: space-between;
            }
        }
        .fuel-fleet-solution-container {
            position: relative;
            margin-right: 16px;
            margin-bottom: 28px;
            width: 100%;

            p {
                color: ${props => props.theme.colors.textGraySecondary};
                font-family: ${props => props.theme.fonts[0]};
                font-size: ${props => props.theme.fontSizes.small};
                font-weight: 500;
                letter-spacing: 0;
                line-height: 1.25rem;
                margin-bottom: 19px;

                span {
                    color: ${props => props.theme.colors.textGraySecondary};
                    font-family: ${props => props.theme.fonts[0]};
                    font-size: 0.75rem;
                    letter-spacing: 0;
                    line-height: 0.875rem;
                }
                b {
                    color: ${props => props.theme.colors.truckerBurgerRed};
                    font-family: ${props => props.theme.fonts[0]};
                    font-size: 0.75rem;
                }
            }
        }
        .form-text {
            font-size: 1.125rem;
            line-height: 2.125rem;
            margin-bottom: 32px;

            &:not(.noTopMargin) {
                margin-top: 32px;
            }
            width: 100%;
            @media screen and (max-width: 768px) {
                align-self: center;
                & ~ h2 .required_text {
                    display: none;
                }
            }
        }

        h2 .required_text {
            position: absolute;
            right: 2%;
            font-size: 0.875rem;
            font-weight: 400;
            text-transform: capitalize;
            &:before {
                content: '';
                position: relative;
                display: inline-block;
                width: 8px;
                height: 20px;
                background-color: ${props => props.theme.colors.primaryYellow};
                margin-right: 5px;
                top: 5px;
            }
        }
        & + input[type='submit'] {
            margin: 1em 1em 5em 0;
            font-weight: 600;
            cursor: pointer;
        }
    }
    .ohnohoney {
        position: absolute;
        opacity: 0;
    }

    .sms__container {
        display: flex;
        flex-direction: column;
        margin-bottom: 12px;

        @media screen and (min-width: 1024px) {
            flex-direction: row;
        }

        .sms__checkbox {
            margin-bottom: 16px;
        }

        .cellphone-prompt {
            color: ${props => props.theme.colors.error};

            @media screen and (min-width: 1024px) {
                margin-left: 8px;
            }
        }
    }

    @media screen and (min-width: 1024px) {
        .flex-row {
            display: flex;
            flex-direction: row;
            & .flex-row {
                width: 50%;
            }
        }

        h2 {
            font-size: 1.875rem;
            align-self: flex-start;
        }
    }
`;

function ContactUsForm({ onSubmit, utmParams, ...props }) {
    const [smsOptInClicked, setSmsOptInClicked] = useState(false);
    const [cellPhone, setCellPhone] = useState([]);
    const [errFuelBuying, setErrFuelBuying] = useState(['']);
    const { register, handleSubmit, errors, control, getValues } = useForm();
    const fuelBuying = [
        {
            name: 'commercialDiesel',
            label: 'Commercial Diesel',
        },
        {
            name: 'gasoline',
            label: 'Gasoline',
        },
        {
            name: 'autoDiesel',
            label: 'Auto Diesel',
        },
    ];
    const validateCheckboxes = () => {
        const values = getValues();
        if (
            !values.autoDiesel &&
            !values.commercialDiesel &&
            !values.gasoline
        ) {
            setErrFuelBuying('Must select one');
            return false;
        } else {
            setErrFuelBuying('');
            return true;
        }
    };
    return (
        <ContactUstyled onSubmit={handleSubmit(onSubmit)}>
            <div className="inputHolder">
                <h2>
                    CONTACT US
                    <strong className="required_text">Required</strong>
                </h2>

                <p className="form-text noTopMargin">
                    Thank you for your interest in the Axle Fuel Card&trade;
                    Application. Please complete the form below and a
                    representative will be in contact with you shortly.
                </p>
            </div>

            <div className="inputHolder">
                <div className="flex-row">
                    <div className="fuel-fleet-solution-container">
                        <p>
                            Please select the fuel types you are interested in
                            purchasing.*
                            <br />
                            <span>Check all that apply</span>{' '}
                            <b>{errFuelBuying}</b>
                        </p>
                        {fuelBuying.map((option, i) => {
                            return (
                                <HookFormCheckboxGroup
                                    key={i}
                                    name={option.name}
                                    label={option.label}
                                    register={register({
                                        validate: validateCheckboxes,
                                    })}
                                    onChange={e => {
                                        return e.target.checked;
                                    }}
                                    defaultChecked={false}
                                ></HookFormCheckboxGroup>
                            );
                        })}
                    </div>
                    <div className="fuel-fleet-solution-container">
                        <p>
                            Are you interested in any other fleet solutions?
                            <br />
                            <span>Check all that apply</span>
                        </p>
                        <HookFormCheckbox
                            name="freightBillFactoring"
                            register={register}
                            label="Factoring Services"
                            checked={false}
                            onChange={e => {
                                return e.target.cheked;
                            }}
                        ></HookFormCheckbox>
                        <HookFormCheckbox
                            name="truckCareMaintenance"
                            register={register}
                            label="Truck Care Maintenance Packages"
                            checked={false}
                            onChange={e => {
                                return e.target.cheked;
                            }}
                        ></HookFormCheckbox>
                        <HookFormCheckbox
                            name="credit"
                            register={register}
                            label="Credit"
                            checked={false}
                            onChange={e => {
                                return e.target.cheked;
                            }}
                        ></HookFormCheckbox>
                    </div>
                </div>
                <div className="flex-row">
                    <div className="flex-row ohnohoney">
                        <input
                            type="hidden"
                            name="UTM_Campaign__c"
                            value={utmParams.campaign}
                            ref={register}
                        />
                        <input
                            type="hidden"
                            name="UTM_Medium__c"
                            value={utmParams.medium}
                            ref={register}
                        />
                        <input
                            type="hidden"
                            name="UTM_Source__c"
                            value={utmParams.source}
                            ref={register}
                        />
                        <HookFormInput
                            type="text"
                            name="honey"
                            id="oh-honey"
                            register={register}
                            value=""
                            required={false}
                            label="User Email"
                        />
                    </div>
                    <HookFormInput
                        name="firstName"
                        register={register}
                        hasError={errors.FirstName && true}
                        errorText={errors.FirstName && errors.FirstName.message}
                        label="First Name"
                        required={true}
                        value={props.FirstName && props.FirstName}
                    />
                    <HookFormInput
                        name="lastName"
                        register={register}
                        hasError={errors.LastName && true}
                        errorText={errors.LastName && errors.LastName.message}
                        label="Last Name"
                        required={true}
                        value={props.LastName && props.LastName}
                    />
                </div>
                <div className="flex-row">
                    <HookFormInput
                        name="company"
                        register={register}
                        hasError={errors.Company && true}
                        errorText={errors.Company && errors.Company.message}
                        label="Company"
                        required={true}
                        value={props.Company && props.Company}
                    />
                </div>
                <div className="flex-row">
                    <HookFormInput
                        name="title"
                        register={register}
                        hasError={errors.Title && true}
                        errorText={errors.Title && errors.Title.message}
                        label="Job Title"
                        required={true}
                        value={props.Title && props.Title}
                    />
                    <HookFormInput
                        name="email"
                        placeholder="YourEmail@place.com"
                        register={register}
                        hasError={errors.Email && true}
                        pattern={/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/}
                        required={true}
                        errorText={errors.Email && errors.Email.message}
                        label="Email Address"
                        value={props.Email && props.Email}
                    />
                </div>

                <div className="flex-row">
                    <HookFormInput
                        type="tel"
                        name="phone"
                        placeholder="###-###-####"
                        register={register}
                        pattern={/\d{3}-?\d{3}-\d{4}/}
                        hasError={errors.PhoneNumber && true}
                        required={true}
                        errorText={
                            errors.PhoneNumber && errors.PhoneNumber.message
                        }
                        label="Phone Number"
                        value={props.PhoneNumber && props.PhoneNumber}
                        maskType="Phone"
                        onChange={e => {
                            setCellPhone(e.target.value);
                        }}
                    />
                    <HookFormInput
                        type="tel"
                        name="cellPhone"
                        placeholder="###-###-####"
                        register={register}
                        pattern={/\d{3}-?\d{3}-\d{4}/}
                        hasError={errors.CellPhoneNumber && true}
                        required={smsOptInClicked}
                        errorText={
                            errors.CellPhoneNumber &&
                            errors.CellPhoneNumber.message
                        }
                        label="Cell Phone Number"
                        value={props.CellPhoneNumber && props.CellPhoneNumber}
                        maskType="Phone"
                        validate={value => {
                            if (getValues()['smsOptIn'] && !value) {
                                return '';
                            }
                        }}
                        onChange={e => {
                            setCellPhone(e.target.value);
                        }}
                    />
                </div>
                <div className="flex-row sms__container">
                    <HookFormCheckbox
                        className="sms__checkbox"
                        name="smsOptIn"
                        register={register}
                        label="I want to receive SMS texts on the status of my submission."
                        onChange={e => {
                            setSmsOptInClicked(e.target.checked);
                            setCellPhone(
                                document.getElementById('cellPhone').value
                            );
                        }}
                        defaultChecked={false}
                    ></HookFormCheckbox>
                    {smsOptInClicked && cellPhone.length < 12 ? (
                        <span className="cellphone-prompt">
                            Please fill out the cell phone field above
                        </span>
                    ) : null}
                </div>
                <div className="flex-row">
                    <HookFormInput
                        name="city"
                        register={register}
                        hasError={errors.City && true}
                        errorText={errors.City && errors.City.message}
                        label="City"
                        required={true}
                        value={props.City && props.City}
                    />
                    <div className="flex-row">
                        <HookFormSelect
                            name="state"
                            register={register}
                            hasError={errors.State && true}
                            errorText={errors.State && errors.State.message}
                            label="State"
                            required={true}
                            defaultValue={props.State && props.State}
                            control={control}
                            options={StateOptions}
                        ></HookFormSelect>
                        <HookFormInput
                            name="zip"
                            register={register}
                            hasError={errors.ZipCode && true}
                            errorText={errors.ZipCode && errors.ZipCode.message}
                            label="Zip Code"
                            required={true}
                            value={props.ZipCode && props.ZipCode}
                            validate={value => {
                                if (value.length < 5 || value.length > 6) {
                                    return 'Enter a valid zip code';
                                }
                            }}
                            maskType="Number"
                        />
                    </div>
                </div>
                <div className="flex-row">
                    <HookFormInput
                        type="text"
                        name="dotNumber"
                        register={register}
                        pattern={/[0-9]*/}
                        hasError={errors.DOTNumber && true}
                        required={true}
                        errorText={errors.DOTNumber && errors.DOTNumber.message}
                        label="DOT #"
                        value={props.DOTNumber && props.DOTNumber}
                        maskType="Number"
                    />
                    <HookFormInput
                        type="text"
                        name="numberOfTrucks"
                        register={register}
                        hasError={errors.NumberOfTrucks && true}
                        required={true}
                        errorText={
                            errors.NumberOfTrucks &&
                            errors.NumberOfTrucks.message
                        }
                        label="Number of Trucks"
                        value={props.NumberOfTrucks && props.NumberOfTrucks}
                        maskType="Number"
                        pattern={/[0-9]*/}
                    />
                </div>
            </div>

            <div className="inputHolder">
                <CreditSubmitButton text="SUBMIT"></CreditSubmitButton>
            </div>
        </ContactUstyled>
    );
}

export default ContactUsForm;
